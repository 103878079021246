<template>
  <v-card class="mx-auto">
    <v-img
      height="200px"
      class="grey"
    />
    <v-card-title>{{ user.uid }}</v-card-title>
    <v-card-subtitle>{{ user.displayName }}</v-card-subtitle>
  </v-card>
</template>

<script>

  export default {
    name: 'UserCard',
    props: {
      user: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      building: false
    })
  }
</script>
